@import "../../styles/styles.scss";

.main {
  display: flex;
  overflow-x: hidden;
  h1 {
    margin-bottom: 0.8rem;
  }
}

@include media-m {
  .main {
    display: block;
  }
}
