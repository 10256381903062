@import "../../styles/styles.scss";

.credits {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: $medium;
  color: $accent-color;

  .logo {
    stroke: $secondary-color;
  }

  a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
    color: $accent-color;

    &:hover {
      color: $secondary-color;
    }
  }
}
