@import "../../styles/styles.scss";

.formula-analyzer {
  margin-top: 2rem;
}

.fa-headers {
  background-color: $primary-color;
  padding: 2rem;
}

.fa-links {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.fa-link {
  color: $secondary-color;
  text-decoration: none;
  font-size: $medium;

  &:hover {
    cursor: pointer;
    color: $accent-color;
  }
}
