@import "../../../styles/styles.scss";

.legal {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.legal-links {
  display: flex;
  gap: 1rem;
}

.legal-link {
  color: $secondary-color;
  text-decoration: underline;

  &:hover {
    //color: $secondary-color-light;
    text-shadow: 0 7px 13px $secondary-color-light-p;
  }
}

.legal-link-active {
  text-shadow: 0 7px 13px $secondary-color-light-p;
}

.legal-text {
  margin-top: 2rem;
}

.eula {
  width: 100%;
  height: 80vh;
}
