@import "../../styles/styles.scss";

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: $secondary-color-light;
}

.header-container {
  width: 80%;
  display: flex;
  justify-content: space-between;
  height: 4.4rem;
  span {
    //color: $primary-color;
  }
}

.header-logo {
  display: flex;
  justify-content: center;
  //background-color: $primary-color;
}

.logo-container {
  //padding: 0.7rem;
  //border-radius: 20px;
  //background-color: $primary-color;
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;

  h1 {
    //color: $primary-color;
    margin: 0;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    // h1 {
    //   color: $accent-color;
    // }
  }
}

.logo {
  width: 3rem;
  height: 3rem;
}

.header-links {
  display: flex;
  justify-content: space-evenly;
  //   background-color: $primary-color;
  //   padding: 1rem;
}

.header-link {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  font-size: $medium;
  //background-color: $ac-color;
  color: $secondary-color;
  margin: 0 1rem;
  padding: 0 1rem;
  //border-radius: 20px;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.header-logo-fa {
  .svg-primary {
    fill: $secondary-color;
  }
  .svg-secondary {
    fill: $primary-color;
  }
  .svg-secondary-light {
    fill: $primary-color;
  }
}

@include media-m {
  .header {
    position: initial;
  }

  .header-container {
    flex-direction: column;
    height: auto;
  }

  .header-links {
    align-items: center;
    margin-top: 1rem;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}
