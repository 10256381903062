@import "./variables";

h1 {
  color: $secondary-color;
  font-family: $body-font;
  font-size: $large;
  letter-spacing: 1px;
}

h2 {
  color: $secondary-color;
  font-family: $body-font;
  font-size: $regular;
}

h3 {
  color: $secondary-color;
  font-family: $body-font;
  font-size: $regular;
}

h4 {
  color: $secondary-color;
  font-family: $heading-font;
  font-size: $regular;
}

h5 {
  color: $secondary-color;
  font-family: $heading-font;
  font-size: $medium;
}

h6 {
  color: $secondary-color;
  font-family: $body-font;
  font-size: $medium;
}

p {
  color: $secondary-color-light-p;
  font-size: $small;
  margin-top: 1rem;
}
