@import "../../../styles/styles.scss";

.textarea-input {
  padding: 15px 0 0;
  width: 100%;
  height: 300px;

  textarea,
  label {
    font-family: $body-font;
    font-size: $small;
    padding: 10px 5px;
  }

  textarea {
    height: 280px;
    resize: none;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $secondary-color-light-p;
    color: $secondary-color;
    font-weight: 500;
    outline: none;
    width: 100%;

    & + label {
      color: $secondary-color-light-p;
      pointer-events: none;
      position: relative;
      top: -280px;
      @include transition-ease;
    }

    // autofill background
    &:-webkit-autofill {
      box-shadow: 0 0 0px 1000px lighten($color: $secondary-color, $amount: 53%) inset;
      border-radius: 2px;
    }

    &:focus,
    &:not(:placeholder-shown),
    &:-webkit-autofill {
      & + label {
        top: -310px;
        padding: 10px 0;
        // color: $secondary-color;
      }
    }
  }

  .input-error {
    border-bottom: 1px solid $error-color;
  }
}

.error-message {
  color: $error-color;
  font-weight: bold;
}
