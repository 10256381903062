@import "./mixins";

//Fonts
$heading-font: "Montserrat", "Helvetica", "Arial", sans-serif;
$body-font: "Poppins", sans-serif;

//Font size
$extra-small: 0.9rem;
$small: 1.2rem;
$medium: 1.3rem;
$regular: 1.7rem;
$large: 2.2rem;

// Colors
$primary-color: #eee;
$secondary-color: #000;
$secondary-color-light: #e6e5e5;
$secondary-color-light-p: #727272;
$accent-color: #204338; //#d7ae33; //4D7EA8
$background-color: $primary-color;
$error-color: #d61c1f;
$green: #198754;

//Bootstrap variables
$theme-colors: (
  "primary": #0000a8,
  "secondary": #95a0b2,
  "info": #d61c1f,
);
