@import "../../../styles/styles.scss";

.ui-form-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ui-form {
    display: flex;
    flex-direction: column;
    color: $background-color;
    width: 100%;
    // @include card;
  }

  .logo-link {
    margin: 0 auto 0.5rem;
  }

  .submit-button {
    @include button;
    margin: 3rem auto 0;
    letter-spacing: 1px;
    color: $background-color;
    height: 3rem;
  }

  .error {
    border: 1px solid $error-color;
    background-color: lighten($color: $error-color, $amount: 45%);
    border-radius: 2px;
    color: $secondary-color;
    font-size: $small;
    margin-top: 2rem;
    margin-bottom: -1rem;
    padding: 10px;
    text-align: center;
  }
}

@include media-m {
  .ui-form-div {
    margin-left: 1px;
    margin-right: 1px;

    .ui-form {
      padding: 2rem 4rem;
    }
  }
}

@include media-s {
  .ui-form-div {
    .ui-form {
      width: 90%;
      padding: 0;
    }

    .logo-link {
      h1 {
        font-size: $small;
      }
    }
  }
}
