@import "../../styles/styles.scss";

#contacts {
  display: flex;
  justify-content: center;
}

.contacts-container {
  margin-top: 2rem;
  border: 1px solid $secondary-color;
  border-radius: 20px;
  width: 60%;
  padding: 2rem;
  display: flex;
  align-items: center;
}

.contacts-form {
  width: 55%;
}

.contact {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contacts-icon {
  color: $secondary-color;
  height: 2rem;
}

.icon-small {
  height: 1.5rem;
}

.copy-clipboard:hover {
  cursor: pointer;
}

.clipboard-msg {
  position: absolute;
  top: 0;
  background-color: white;
  padding: 0.2rem;
  border-radius: 10px;
  font-size: $small;
  color: $secondary-color;
}

.contacts-data {
  padding: 2rem;
  position: relative;

  a {
    text-decoration: none;
  }
}

.email {
  color: $secondary-color;
}

@include media-m {
  .contacts {
    display: block;
  }

  .contacts-container {
    flex-direction: column;
    width: 100%;
  }

  .contacts-form {
    width: 100%;
  }

  .contacts-data {
    padding: 0;
    margin-top: 2rem;
    text-align: center;
  }

  .contact {
    flex-direction: column;
    gap: 0;
  }

  .clipboard-msg {
    position: initial;
    top: initial;
  }
}

.credit-terms {
  display: block;
  margin: 1rem auto 0 auto;
  width: fit-content;

  &:hover {
      color: $accent-color;
  }
}

@include media-s {
  .email {
    font-size: 1rem;
  }
}
