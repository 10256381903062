@import "../../../styles/styles.scss";

.spinner {
  &::after {
    content: " ";
    display: block;
    border-radius: 50%;
    border: 4px solid #fff;
    border-color: #fff #fff #fff transparent;
    animation: spinner 1.2s linear infinite;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  display: flex;
  justify-content: center;

  .spinner {
    display: inline-block;
    aspect-ratio: 1 / 1;
  }

  .spinner::after {
    border-color: $primary-color $primary-color lighten($color: $primary-color, $amount: 50%);
    height: 100%;
    aspect-ratio: 1 / 1;
  }
}