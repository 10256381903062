@import "../../../styles/styles.scss";

.spinner {
  display: inline-block;

  &::after {
    content: " ";
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 4px solid #fff;
    border-color: #fff #fff #fff transparent;
    animation: spinner 1.2s linear infinite;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  margin: 15% auto;
  font-family: $heading-font;
  letter-spacing: 1px;
  font-size: 1.5rem;
  width: 3rem;

  .spinner::after {
    border-color: $primary-color $primary-color lighten($color: $primary-color, $amount: 50%);
    height: 5rem;
    width: 5rem;
  }
}
